import React, { useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { Nav } from './Nav'

export const GlobalHeader = () => {
    const [mobileNavOpen, setMobileNavOpen] = useState(false)

    return (
        <Box
            as="header"
            className="siteHeader"
            bg="oriGrayscale.50"
            height={16}
            zIndex={2}
            position="fixed"
            top={0}
            left={0}
            width="100%"
        >
            <Flex className="siteHeaderInner" justifyContent="flex-start" p={4}>
                <Nav mobileNavOpen={mobileNavOpen} onMobileNavOpenChange={setMobileNavOpen} />
            </Flex>
        </Box>
    )
}
