import React from 'react'
import { Box, Button, ListItem, UnorderedList, useToken } from '@chakra-ui/react'
import { Link } from './Link'

const topline = 'M0,2 L10,2'
const midline = 'M0,5 L10,5'
const lowline = 'M0,8 L10,8'
const zeroedMidline = 'M0,5 L0,5'
const upwardDiagonalLine = 'M1,9 L9,1'
const downwardDiagonalLine = 'M1,1 L9,9'

export const Nav = ({ mobileNavOpen, onMobileNavOpenChange }) => {
    const [oriGrayscale900] = useToken('colors', ['oriGrayscale.900'])

    const handleClick = (event) => {
        const toggleMenuButton = event.target.closest('button')
        if (toggleMenuButton) {
            const openState = !mobileNavOpen
            onMobileNavOpenChange(openState)
            if (openState) {
                toggleMenuButton.querySelector('path.topline').setAttribute('d', downwardDiagonalLine)
                toggleMenuButton.querySelector('path.midline').setAttribute('d', zeroedMidline)
                toggleMenuButton.querySelector('path.lowline').setAttribute('d', upwardDiagonalLine)
            } else {
                toggleMenuButton.querySelector('path.topline').setAttribute('d', topline)
                toggleMenuButton.querySelector('path.midline').setAttribute('d', midline)
                toggleMenuButton.querySelector('path.lowline').setAttribute('d', lowline)
            }
        }
    }

    const pages = [
        {
            path: '/',
            title: 'Home',
        },
        {
            path: '/library-site-review',
            title: 'Surveying the Landscape of Public Library Websites',
        },
        {
            path: '/app',
            title: 'Mobile App',
        },
        {
            path: '/evergreen',
            title: 'Evergreen Integration',
            disabled: true,
        },
        {
            path: '/features',
            title: 'Highlighted Features',
            disabled: true,
        },
        {
            path: '/user-stories',
            title: 'Personas and User Stories',
            disabled: true,
        },
        {
            path: '/timeline',
            title: 'Project Timeline',
            disabled: true,
        },
        {
            path: '/service-details',
            title: 'Service and Plan Details',
            disabled: true,
        },
        {
            path: '/portfolio',
            title: 'Expanded Portfolio and References',
            disabled: true,
        },
    ]

    return (
        <Box as="nav">
            <Button
                className="mainNavToggle"
                aria-expanded="false"
                aria-controls="menu"
                aria-label="Menu"
                variant="link"
                position="fixed"
                top={2}
                left={1}
                width={4}
                mx={2}
                zIndex={1001}
                onClick={handleClick}
                sx={{
                    path: {
                        stroke: mobileNavOpen ? 'oriGrayscale.50' : 'oriGrayscale.900',
                        strokeWidth: '0.025em',
                        transition: '0.2s',
                    },
                }}
            >
                <svg aria-hidden="true" focusable="false" viewBox="0 0 10 10">
                    <path className="topline" d="M0,2 L10,2" />
                    <path className="midline" d="M0,5 L10,5" />
                    <path className="lowline" d="M0,8 L10,8" />
                </svg>
            </Button>

            <UnorderedList
                position="fixed"
                top={0}
                left={0}
                width="100%"
                height="100%"
                margin={0}
                background={`${oriGrayscale900}FA`}
                color="oriGrayscale.50"
                listStyleType="none"
                zIndex={1000}
                maxHeight={mobileNavOpen ? '100vh' : 0}
                transition="0.3s all"
                overflowY="scroll"
                padding={mobileNavOpen ? 8 : 0}
                pl={16}
                pt={mobileNavOpen ? 16 : 0}
            >
                {pages.map((page, index) =>
                    page?.title ? (
                        <ListItem key={index} className="mainNavItem" py={4}>
                            {page?.disabled ? (
                                <Box as="span" opacity="0.8" _hover={{ cursor: 'not-allowed' }}>
                                    {page.title}
                                </Box>
                            ) : (
                                <Link className="navLink" destination={page.path}>
                                    {page.title}
                                </Link>
                            )}
                        </ListItem>
                    ) : null
                )}
            </UnorderedList>
        </Box>
    )
}
