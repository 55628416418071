import { Box, useToken } from '@chakra-ui/react'
import React from 'react'
import { GlobalHeader } from './GlobalHeader'

export const PageLayout = ({ hero, children }) => {
    const books = [...Array(40)]
    let latestBookLocation = 0
    const bookColors = useToken('colors', [
        'oriGrayscale.100',
        'oriBlue.200',
        'oriOrange.100',
        'oriTeal.100',
        'oriBlue.100',
    ])

    return (
        <Box
            className="pageLayout"
            minHeight="100vh"
            position="relative"
            pt={16}
            pb="20%"
            bg="oriGrayscale.50"
            color="oriGrayscale.900"
        >
            <GlobalHeader />
            {hero && hero}
            {children && (
                <Box className="pageMain" as="main" mb={6}>
                    {children}
                </Box>
            )}

            <Box
                className="footerGraphic"
                as="svg"
                width="100%"
                viewBox="0 0 1000 150"
                xmlns="http://www.w3.org/2000/svg"
                position="absolute"
                bottom="5px"
            >
                {books.map((x, i) => {
                    const bookWidth = 15 + Math.round(Math.random() * 30)
                    const bookHeight = 120 + Math.round(Math.random() * 25)
                    const book = (
                        <rect
                            key={i}
                            x={latestBookLocation}
                            y={150 - bookHeight}
                            width={bookWidth}
                            height={bookHeight}
                            fill={bookColors[Math.floor(Math.random() * bookColors.length)]}
                        />
                    )
                    latestBookLocation += bookWidth + 5

                    return book
                })}
            </Box>
        </Box>
    )
}
